import React from "react";
import { Router } from "@reach/router";
import NewsPage from "../components/NewsPage";
import NotFoundPage from "./404";

const newsPageRouter = (props) => (
  <Router>
    <NewsPage {...props} path="/news/" />
    <NotFoundPage default />
  </Router>
);

export default newsPageRouter;
