import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const NewsContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFile {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }
        allNewsPageJson {
          edges {
            node {
              id
              pageTitle
              hideNews
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
        allArticlesJson(
          filter: {
            title: { ne: "Default card(Do not delete and keep it hidden)" }
          }
          sort: { fields: date, order: DESC }
        ) {
          edges {
            node {
              id
              hideCard
              date(formatString: "MMMM DD YYYY")
              title
              brief
              articleType
              externalLink
              document
              handWrittenArticle
            }
          }
        }
      }
    `}
    render={({
      allNewsPageJson: { edges: newsPageData },
      allArticlesJson: { edges: articlesData },
      allFile: { edges: filesData },
    }) =>
      children(
        newsPageData.map((e) => ({
          ...e.node,
        })),
        articlesData.map((e) => ({
          ...e.node,
        })),
        filesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

NewsContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default NewsContainer;
