import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import Image from "../Image";
import LinkTo from "../LinkTo";

const NewsCard = ({
  cardDate,
  cardContent,
  cardTitle,
  externalLink,
  readMoreSx,
  sx,
  url,
}) => (
  <LinkTo
    hoverSx={{
      ".read-more": { color: "white" },
      bg: "primaryLight",
      borderColor: "primaryLight",
      color: "white",
      h3: { color: "white" },
    }}
    isLink={externalLink}
    sx={{
      ".read-more": { ...readMoreSx },
      borderColor: "text.para",
      borderStyle: "solid",
      borderWidth: 1,
      boxShadow: 0,
      color: "text.para",
      display: "flex",
      flex: "auto",
      flexDirection: "column",
      h3: { color: "primaryLight" },
      px: [3, null, null, 5],
      py: 4,
      transition: ".35s",
      ...sx,
    }}
    target="_blank"
    to={url}
    // to={`/news/${titleToPath(cardTitle)}`}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: ["center", null, null, "left"],
      }}
    >
      <Image
        svgIcon="icon-calendar"
        sx={{ height: "2.5rem", mr: 2, my: "auto", width: "2.5rem" }}
      />
      <p>{cardDate}</p>
    </Box>
    <Box
      sx={{
        fontSize: 3,
        fontWeight: "light",
        my: 3,
      }}
    >
      <h3>{cardTitle}</h3>
    </Box>
    <p>{cardContent.substring(0, 200)}...</p>
    <Box
      className="read-more"
      sx={{
        alignItems: "center",
        display: "flex",
        fontWeight: "bold",
        justifyContent: ["center", null, null, "left"],
        mb: 2,
        pt: 4,
      }}
    >
      <span>Read More </span>
      <Image
        svgIcon="icon-arrow"
        sx={{
          alignItems: "center",
          display: "flex",
          height: "3rem",
          ml: 2,
          width: "3rem",
        }}
      />
    </Box>
  </LinkTo>
);

NewsCard.propTypes = {
  cardContent: PropTypes.string,
  cardDate: PropTypes.string,
  cardTitle: PropTypes.string,
  childrenSx: PropTypes.shape({}),
  externalLink: PropTypes.string,
  hoverSx: PropTypes.shape({}),
  readMoreSx: PropTypes.shape({}),
  sx: PropTypes.shape({}),
  url: PropTypes.string,
};

NewsCard.defaultProps = {
  cardContent: "",
  cardDate: "",
  cardTitle: "",
  childrenSx: {},
  externalLink: "",
  hoverSx: {},
  readMoreSx: {},
  sx: {},
  url: "",
};
export default NewsCard;
