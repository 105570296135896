import React from "react";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import Section from "../Section";
import NewsContainer from "../../containers/NewsContainer";
import NewsCard from "../CommonComponents/NewsCard";
import PageHeroBanner from "../PageHeroBanner";
import { titleToPath } from "../../helper/helper";

const NewsPage = () => (
  <NewsContainer>
    {(newsPageData, articlesData, filesData) => {
      const pageId = "news";
      const newsInfo = newsPageData.find((data) => data.id === pageId);
      const { heroBannerSection, hideNews } = newsInfo;
      const filesInfo = [];
      filesData.map((file) => filesInfo.push(file.publicURL));
      return (
        <>
          <SEO pathname="news" templateTitle={newsInfo.pageTitle} />
          {heroBannerSection && (
            <PageHeroBanner
              actionButton={heroBannerSection.actionButton}
              bannerImage={heroBannerSection.bannerImage}
              content={{
                heading: heroBannerSection.heading,
                text: heroBannerSection.content,
              }}
            />
          )}
          {!hideNews && (
            <Section>
              <Box
                sx={{
                  mb: [2, null, null, 5],
                  textAlign: ["center", null, null, "left"],
                }}
                variant="markdown.h2"
              >
                News
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  // lineHeight: "2.5rem",
                  mx: -4,
                  textAlign: ["center", null, null, "left"],
                }}
              >
                {articlesData.map(
                  (article) => {
                    const documentPublicURL = filesInfo.find(
                      (publicURL) =>
                        article.document &&
                        publicURL.includes(
                          article.document.split("/").filter((e) => e !== "")[1]
                        ) &&
                        publicURL
                    );
                    return (
                      !article.hideCard && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            px: 4,
                            py: [3, null, null, 4],
                            width: ["100%", null, "calc(100% / 2)"],
                          }}
                        >
                          <NewsCard
                            cardContent={article.brief}
                            cardDate={article.date}
                            cardTitle={article.title}
                            externalLink={
                              article.articleType === "externalLink"
                                ? article.externalLink
                                : article.articleType === "document" &&
                                  documentPublicURL
                            }
                            readMoreSx={{ color: "yellowDark" }}
                            sx={{ borderWidth: 0, boxShadow: 3 }}
                            url={
                              article.articleType === "handWrittenArticle" &&
                              `/news/${titleToPath(article.title)}`
                            }
                          />
                        </Box>
                      )
                    );
                  }
                  // });
                )}
              </Box>
            </Section>
          )}
        </>
      );
    }}
  </NewsContainer>
);

NewsPage.propTypes = {};

export default NewsPage;
